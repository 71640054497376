.calendar-wrap {
    padding-top: 5rem;
    margin: 0 -.5rem;
}

.fc-header-toolbar {
    margin: 0 .5rem;
}

.fc .fc-col-header-cell-cushion {
    color: #f1f1f1;
    font-weight: 500;
}

.fc .fc-daygrid-day {
    color: #f1f1f1;
    padding: .5rem;
}

.fc .fc-scrollgrid-section-sticky > * {
    background: transparent;
}

.fc-daygrid-day-frame {
    border: 1px solid var(--color-gold);
    border-radius: var(--border-radius);
    background: var(--bg-gray);
    min-height: 9rem !important;
    height: 100%;
}

.event__wrap {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    justify-content: center;
}

.event__wrap:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.event__icon {
    margin: 0 auto;
    padding: .2rem;
}

.event__icon__twitch {
    width: min(75%, 50px);
}

.event__icon__datdrop {
    width: min(75%, 6rem);
}

.event__icon__empiredrop {
    width: min(75%, 6rem);
}

.event__icon__kick {
    width: min(75%, 5rem);
}
@media screen and (max-width: 768px) {
    .fc-col-header-cell-cushion {
        font-size: 0.8rem;
    }

    .fc-daygrid-day-frame {
        min-height: 5rem !important;
    }

    .fc-daygrid-day {
        padding: .1rem !important;
        overflow: hidden !important;
        flex-wrap: wrap;
    }

    .event__time {
        font-size: 0.5rem !important;
        white-space: normal !important;
        text-align: center !important;
    }
}