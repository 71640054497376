.competition {
    text-align: center;
}

.competition p {
    max-width: 35rem;
    margin: 1rem auto;
}

.competition img {
    max-width: 100%;
    display: inline-flex;
}