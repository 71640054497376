header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.2rem 0;
    z-index: 200;
}

header.scrolled {
    background: var(--bg-purple);
    box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
    transition: background-color 0.3s, box-shadow 0.3s;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav h1 a {
    color: #f1f1f1;
    text-decoration: none;
}

.menu-icon {
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
    z-index: 2;
}

.menu-icon div {
    width: 30px;
    height: 3px;
    background-color: #f1f1f1;
    transition: transform 0.3s ease, opacity 0.2s ease;
}

.menu-icon.open div:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.menu-icon.open div:nth-child(2) {
    opacity: 0;
}

.menu-icon.open div:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

nav ul {
    display: flex;
    gap: 2rem;
    list-style: none;
}

nav ul li a {
    color: #ccc;
    text-decoration: none;
    font-weight: 600;
}

nav ul li a:hover {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.nav-links {
    display: none;
    margin: 0;
    padding: 0;
}

.nav-links.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.98);
    list-style: none;
    text-align: center;
}

.logo__link {
    display: flex;
    align-items: center;
    gap: .5rem;
    transition: all .3s;
}

.logo__link:hover {
    transform: scale(1.02);
}

.logo__link img {
    margin-bottom: .15rem;
}

.dropdown__toggle {
    cursor: pointer;
    color: var(--text-gray);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .4rem;
}

.dropdown__toggle:hover {
    color: var(--text-white);
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    width: 100%;
    min-width: 180px;
    z-index: 4;
    margin: 0;
    padding: 0;
    top: 2rem;
    background: var(--bg-purple);
    box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
    border-radius: var(--border-radius);
}

.dropdown-menu.show {
    display: block;
}

.dropdown-menu li {
    text-decoration: none;
    display: block;
}

.dropdown-menu li a {
    color: var(--text-gray);
    padding: 1rem 1rem;
    display: block;
    transition: all .3s;
}

.dropdown-menu li a:hover {
    transform: translateX(.3rem);
}

.nav-links li a {
    display: flex;
    align-items: center;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

@media (min-width: 1024px) {
    .nav-links {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}