footer {
    background: #210802 !important;
    padding: 3rem 0rem;
}

.footer__content {
    display: flex;
    justify-content: space-between;
}

.be__gamble__aware {
    width: 500px;
}

h4 {
    color: #f1f1f1;
    text-transform: uppercase;
    margin: 0;
}

footer p {
    color: #ccc !important;
    font-size: .8rem;
    margin: 0;
}

.copyright {
    text-align: center;
    color: #888;
    margin-top: 2rem;
    font-size: .7rem;
}

.socials {
    display: flex;
    gap: 1.5rem;
}

.socials a {
    color: #fff;
    opacity: .7;
    text-decoration: none;
    transition: opacity .3s;
}

.socials a:hover {
    opacity: 1 !important;
    transform: translateY(-.1rem);
}

.social__link {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .footer__content {
        flex-direction: column;
        align-items: center;
    }
    
    .be__gamble__aware {
        width: 100%;
    }
    
    .socials {
        margin-top: 1rem;
        align-items: center;
        justify-content: center;
    }

    .be__gamble__aware {
        margin-top: 2rem;
    }
    
}