.referrals__wrap {
    padding-top: 8rem;
}

.referrals {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 2rem;
}

.referral__box {
    background: #1a1a1a;
    border-radius: .3rem;
    border: 1px solid #FFCC00;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center; 
    position: relative;
    overflow: hidden;
    height: 220px;
    text-decoration: none;
    transition: all .2s ease;
}

.referral__box:hover {
    transform: translateY(-.2rem);
}

.referral__box img {
    max-width: 125px;
    height: auto;
    object-fit: cover;
    margin-bottom: auto;
    margin-top: auto;
}

.bonus {
    font-size: 1rem;
    color: #80ED6A !important;
    font-weight: 600;
    text-decoration: none;
    margin-top: .5rem;
}

@media (max-width: 1200px) {
    .referrals {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .referrals {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 400px) {
    .referrals {
        grid-template-columns: repeat(1, 1fr);
    }
}
