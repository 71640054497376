.tier__wrapper {
    display: flex;
    gap:2rem;
    justify-content: center;
}

.tier__wrapper .previous-week {
    opacity: .5;
}

#empiredrop .tier__subtitle {
    margin: .5rem 0;
}

@media screen and (max-width: 768px) {
    .tier__wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tier__wrapper:nth-child(odd) {
        flex-direction: column-reverse;
    }
    
}