.socials__wrap {
    background: var(--bg-gray);
    border: 1px solid var(--color-gold);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 7rem;
    position: relative;
}

.big__socials {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 5rem;
}

.big__socials .socials__wrap {
    color: #fff;
    text-decoration: none;
    transition: all .2s;
}

.big__socials .socials__wrap:hover {
    transform: translateY(-.1rem);
}

.big__socials a {
    color: #fff;
    text-decoration: none;
    transition: opacity .3s;
}

.big__socials a:hover {
    opacity: 1 !important;
}

.socials__wrap .live__icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

@media screen and (max-width: 768px) {
    .big__socials {
        gap: 1rem;
        flex-wrap: wrap;
        margin-top: 2rem;
    }

    .socials__wrap {
        width: 5.5rem;
        height: 5.5rem;
    }

    .socials__wrap .live__icon {
        top: .5rem;
        right: .5rem;
    }
}