.checkmark {
    color: var(--color-green) !important; 
}

.cashout__table {
    color: var(--text-white);
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
    /* background: var(--bg-gray); */
    /* background: rgba(var(--bg-purble-rgb), 0.5) !important; */
}

.cashout__table th {
    text-align: center;
    padding: .5rem 0rem;
    padding-top: 1rem !important;
    position: sticky !important;
    top: 10rem;
    z-index: 10;
}

.cashout__table td {
    padding: 1rem 0rem;
    text-align: center;
}

.note {
    max-width: 40rem;
    text-align: center;
    margin: 0 auto;
    margin-bottom: .5rem;
}

.search__wrapper {
    margin-bottom: 2rem;
}

.cashout__table tr {
    border-bottom: 1px solid #333;
}

button {
    background-color: transparent;
    color: var(--fc-button-text-color);
    border: 1px solid var(--fc-button-border-color);
    border-radius: var(--border-radius);
    padding: .5rem 1rem;
    cursor: pointer;
    transition: background-color .3s, border-color .3s;
    margin-bottom: 1rem;
}

.display__text {
    margin-bottom: .5rem;
}

.cashout__table th, .cashout__table td {
    padding: 1rem 0rem;
    border-right: 1px solid #222;
}

.cashout__table th:last-child, .cashout__table td:last-child {
    border-right: none;
}

.cashout__table th:first-child, .cashout__table td:first-child {
    text-align: left;
    padding-left: 1rem;
}

.note a {
    color: var(--color-gold);
}

@media (max-width: 768px) {
    .cashout__table th, .cashout__table td {
        padding: .5rem 0.2rem;
        font-size: 0.6rem;
    }

    .search__wrapper, .display__text, .note {
        padding: 0 10px;
    }
}
