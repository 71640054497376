@tailwind base;
@tailwind components;
@tailwind utilities;

img {
	display: inline-block;
	vertical-align:baseline;
}

* {
  box-sizing: border-box;
  font-family: 'AllRoundGothic', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1a1a1a;
}

p {
	color: #ccc;
    font-size: .9rem;
    margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom__container {
	width: min(calc(100% - 2rem), 1400px);
	margin: 0 auto;
	padding: 0 1rem;
}

.wide__container {
	width: min(calc(100% - 2rem), 1800px);
	margin: 0 auto;
	padding: 0 1rem;
}

.discord__link {
	color: var(--color-gold);
	text-decoration: none;
}

.discord__link:hover {
	color: var(--color-gold);
	text-decoration: underline;
}

.text-muted {
	color: var(--text-gray);
	font-size: .8rem;
}

.not__found {
	text-align: center;
	padding-top: 20rem;
	height: 100vh;
}

.blocks__wrapper a {
    color: var(--color-gold);
}

.blocks__wrapper a:hover {
    color: var(--color-gold);
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
	.custom__container {
		width: 100%;
	}

	.wide__container {
		width: 100%;
	}
}

:root {
	--border-radius: .3rem;
	--color-gold: #FFCC00;

	--bg-gray: #1a1a1a;
	--bg-purple: #24141E;
	--bg-purble-rgb: 36, 20, 30;
	
	--text-white: #f1f1f1;
	--text-gray: #ccc;
	--color-bronze: #83491D;
	--color-silver: #C0C0C0;
	--color-green: #80ED6A;
	--text-black: #030303;
	--color-diamond: #00FFD1;

	--fc-border-color: transparent;
	--fc-neutral-text-color: #f1f1f1;
	--fc-small-font-size: .85em;
	--fc-page-bg-color: #333;
	--fc-neutral-bg-color: rgba(208, 208, 208, 0.3);

	--fc-button-text-color: #f1f1f1;
	--fc-button-bg-color: #333333;
	--fc-button-border-color: #474747;
	--fc-button-hover-bg-color: #1e2b37;
	--fc-button-hover-border-color: #1a252f;
	--fc-button-active-bg-color: #1a252f;
	--fc-button-active-border-color: #151e27;

	--fc-event-bg-color: var(--color-gold);
	--fc-event-border-color: transparent;
	--fc-event-text-color: var(--text-black);
	--fc-event-selected-overlay-color: transparent;

	--fc-more-link-bg-color: #d0d0d0;
	--fc-more-link-text-color: inherit;

	--fc-event-resizer-thickness: 8px;
	--fc-event-resizer-dot-total-width: 8px;
	--fc-event-resizer-dot-border-width: 1px;

	--fc-non-business-color: rgba(215, 215, 215, 0.3);
	--fc-bg-event-color: rgb(143, 223, 130);
	--fc-bg-event-opacity: 0.3;
	--fc-highlight-color: rgba(188, 232, 241, 0.3);
	--fc-today-bg-color: rgba(255, 0, 0, 0.3);
	--fc-now-indicator-color: red;
}

.subtitle {
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: 400;
	color: var(--text-white);
}

.text-center {
	text-align: center;
}

section {
	padding: 10rem 0rem;
}

.title {
	font-size: 2rem;
	text-transform: uppercase;
}

.markdown ul {
	color: var(--text-gray);
	font-size: .9rem;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-BoldOblique.ttf') format('truetype');
	font-weight: bold;
	font-style: oblique;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-BookOblique.ttf') format('truetype');
	font-weight: normal;
	font-style: oblique;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-Demi.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-DemiOblique.ttf') format('truetype');
	font-weight: 600;
	font-style: oblique;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-MediumOblique.ttf') format('truetype');
	font-weight: 500;
	font-style: oblique;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-XLig.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'AllRoundGothic';
	src: url('./static/fonts/AllRoundGothic-XLigOblique.ttf') format('truetype');
	font-weight: 200;
	font-style: oblique;
}

/* ANIMATIONS */
.fly-left {
	-webkit-animation: flyInFromLeft .5s ease-in-out;
	animation: flyInFromLeft .5s ease-in-out;
  }
  
  .fly-right {
	-webkit-animation: flyInFromRight .5s ease-in-out;
	animation: flyInFromRight .5s ease-in-out;
  }
  
  .fly-bottom {
	-webkit-animation: flyInFromBottom .5s ease-in-out;
	animation: flyInFromBottom .5s ease-in-out;
  }
  
  .fade-in {
	-webkit-animation: fadeIn .5s ease-in-out;
	animation: fadeIn .5s ease-in-out;
  }
  
  /* Start animations with forwards */
  .start-animation.fly-left-late {
	-webkit-animation: flyInFromLeft .5s ease-in-out forwards;
	animation: flyInFromLeft .5s ease-in-out forwards;
  }
  
  .start-animation.fly-right-late {
	-webkit-animation: flyInFromRight .5s ease-in-out forwards;
	animation: flyInFromRight .5s ease-in-out forwards;
  }
  
  .start-animation.fly-bottom-late {
	-webkit-animation: flyInFromBottom .5s ease-in-out forwards;
	animation: flyInFromBottom .5s ease-in-out forwards;
  }
  
  .start-animation.fade-in-late {
	-webkit-animation: fadeIn .5s ease-in-out forwards;
	animation: fadeIn .5s ease-in-out forwards;
  }
	
  /* fadeIn */
  @-webkit-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
  }
  @-moz-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
  }
  @-o-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
  }
  @keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
  }
  
  /* flyInFromRight */
  @-webkit-keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @-moz-keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @-o-keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
  
  /* flyInFromLeft */
  @-webkit-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @-moz-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @-o-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  
  /* flyInFromBottom */
  @-webkit-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @-moz-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @-o-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  
  /* moveUpDown */
  @-webkit-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @-moz-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @-o-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }