.youtube__wrap {
    margin-top: 5rem;
}

.youtube {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}

.video {
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    background: #1a1a1a;
    border-radius: .3rem;
    border: 1px solid #821B0A;
    transition: transform .3s, box-shadow .3s;
    overflow: hidden;
}

.video img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: opacity .3s;
}

.video h3 {
    color: #f1f1f1;
    font-weight: 400;
    line-height: 1.7rem;
    margin: 0;
    margin-top: .4rem;
}

.video:hover {
    transform: translateY(-.2rem);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.video:hover img {
    opacity: 0.3;
}

.icon-overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff0000;
    font-size: 4rem;
    opacity: 0;
    transition: opacity .3s;
}

.video:hover .icon-overlay {
    opacity: 1;
}

@media (max-width: 768px) {
    .youtube {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .youtube {
        grid-template-columns: 1fr;
    }
}
