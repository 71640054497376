.maintenance {
    margin-top: 10rem;
    max-width: 40rem;
}

.tiers__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates two columns of equal width */
    gap: 1.5rem; /* Maintains space between the grid items */
    justify-content: center; /* Centers the grid within the container */
    margin-top: 2rem;
}

.tier {
    background: var(--bg-gray);
    padding: 1.5rem;
    width: 100%;
    min-width: 400px;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tier__title {
    font-weight: 800;
    margin-bottom: .1rem;
}

.tier__subtitle {
    text-align: center;
}

.tier__bonus {
    color: var(--color-green) !important;
    text-align: center;
    font-size: 1.1rem;
    padding: .5rem;
    font-weight: 500;
}

.tier__deposit {
    display: flex;
    justify-content: space-between;
}

.tier__deposits-table {
    width: 100%;
    border-collapse: collapse;
}

.tier__deposits-table th, .tier__deposits-table td {
    padding: .5rem;
    text-align: left;
}

.tier__deposits-table th {
    color: var(--text-gray);
    font-size: .8rem;
    font-weight: 400;
}

.reward {
    color: var(--color-green) !important;
}

.non-tier-results {
    margin-top: 1.5rem;
}

.tier-1 {
    border: 2px solid var(--color-bronze);
}

.tier-1 .tier__title {
    color: var(--color-bronze);
}

.tier-2 {
    border: 2px solid var(--color-silver);
}

.tier-2 .tier__title {
    color: var(--color-silver);
}

.tier-3 {
    border: 2px solid var(--color-gold);
}

.tier-3 .tier__title {
    color: var(--color-gold);
}

.tier-4 {
    border: 2px solid var(--color-diamond);
}

.tier-4 .tier__title {
    color: var(--color-diamond);
}

.search-input {
    padding: 0.8rem 1rem;
    border-radius: var(--border-radius);
    border: 2px solid var(--color-gray);
    font-size: 1rem;
    width: min(100%, 500px);
    margin-top: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border-color 0.3s ease;
}

.search-input:focus {
    border-color: var(--color-primary);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.last__week__wrapper {
    opacity: .5;
    margin-top: 10rem;
    margin-bottom: 3rem;
}

.tiers__section a {
    color: var(--color-gold);
}

.info__squares {
    position: absolute;
    top: 6rem;
    right: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.warning__square {
    background: var(--bg-gray);
    padding: 1rem;
    text-align: center;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ff0000;
}

.warning__square p {
    font-size: .8rem;
}

.last__week__bonuses {
    background: var(--bg-gray);
    width: 13rem;
    padding: 1rem;
    text-align: center;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--color-gold);
}

.last__week__bonuses p {
    font-size: .8rem;
    margin-bottom: .5rem;
}

.last__week__bonuses .reward {
    font-size: 1.5rem;
    font-weight: 600;
}

.big__timer {
    font-size: 4rem;
    margin: 0;
    text-transform: lowercase;
    margin-bottom: 3rem;
}

.info__wrapper {
    display: flex;
    align-items: stretch;
    gap: 4rem;
    margin-bottom: 4rem;
}

.info__wrapper > * {
    flex: 1;
}

@media screen and (max-width: 1670px) {
    .tiers__wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 900px) {
    .tiers__wrapper {
        grid-template-columns: 1fr;
    }

    .tier {
        max-width: 100% !important;
        min-width: 350px;
    }

    .info__squares {
        position: relative;
        margin-bottom: 2rem;
        top: 0;
        right: 0;
    }

    .last__week__bonuses {
        width: min(100%, 300px)
    }

    .search__wrapper {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    .big__timer {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .info__wrapper {
        flex-direction: column;
        gap: 2rem;
    }
}