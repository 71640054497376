.battles-table {
    color: var(--text-white);
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
    margin-top: 2rem;
}

.battles-table th {
    text-align: left;
    padding: 0rem .5rem;
}

.battles-table td {
    padding: 1rem .5rem;
}

.requirement {
    max-width: 13rem;
}

.requirement p {
    font-size: .8rem;
}

@media screen and (max-width: 768px) {
    #datdrop .title {
        margin-bottom: 1rem;
    }
    
    .battles-table th {
        display: none;
    }

    .battles-table td {
        display: block;
        padding: .5rem 0rem;
    }

    .battles-table tr {
        border-bottom: 1px solid #333;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1rem;
        text-align: center;
        padding: 1rem 0rem;
    }

    .requirement {
        max-width: 100%;
    }
}