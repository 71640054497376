.App {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  /* background: linear-gradient(180deg, #D7593C -10rem, #24141E 19rem) no-repeat; */
}

#bg-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -2;
  transform: translate(-50%, -50%);
  background-size: cover;
  pointer-events: none;
  max-width: none;
}

.App::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

h1 {
  font-size: 1.5rem;
  margin: 0;
  color: #fff;
  opacity: .9;
}

h2 {
	font-size: 1.5rem;
	font-weight: 500;
	text-align: center;
  text-transform: uppercase;
  color: var(--text-white);
}

@keyframes float {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}